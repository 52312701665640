.work-empire {
    position: relative;
    color: black;
    overflow-x: hidden;
    
//     position: relative;
//     height: auto;
//     width: 100vw;
//     min-height: 100vh;
//     height: auto;
//     margin: 0 auto;
//     overflow: hidden;

//     display: flex;
//     justify-content: center;
//     align-items: center;

//     .title-section {
//         width: 100vw;
//         height: 100vh !important;

//         background-color: hsl(0, 22%, 89%) !important;
//         color: black;
//     }

//     .horizontal-section {
//         width: 100vw;
//         height: 100vh;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//     }
}