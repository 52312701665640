  
  body {
    font-family: termina, sans-serif;
    transition: 0.3s ease-out;
    overflow-x: hidden;
    max-width: 100%;
    width: 100%;
    overscroll-behavior: none;
  }
  
  section:not(#sectionPin) {
    min-height: 100vh;
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 2rem;
    // padding: 100px 0vw;
    margin: auto;
    place-items: center;
  }
  
  // img {
  //   height: 70vh;
  //   width: auto;
  //   object-fit: cover;
  // }
  
  h1 {
    font-size: 5rem;
    line-height: 1;
    font-weight: 800;
    margin-bottom: 1rem;
    // position: absolute;
    z-index: 4;
    overflow-wrap: break-word;
    hyphens: auto;
  
    @media (max-width: 768px) {
      font-size: 16vw;
    }
  
    span {
      display: block;
    }
  }
  
  h2 {
    font-size: 2rem;
    max-width: 400px;
  }
  
  .credit {
    font-family: Termina, sans-serif;
    a {
      color: var(--text-color);
    }
  }
  
  * {
    box-sizing: border-box;
  }
  
  #sectionPin {
    height: 100vh;
    overflow: hidden;
    display: flex;
    left: 0;
  }
  
  .pin-wrap {
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 50px 10vw;
  
    & > * {
      min-width: 60vw;
      padding: 0 5vw;
    }
  }
  
  // p {
  //   position: absolute;
  //   bottom: 10vw;
  //   right: 10vw;
  //   // width: 200px;
  //   line-height: 1.5;
  // }
  