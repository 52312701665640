.wrapper-left{
    position:relative;
    width:150%;
    height:50px;
    right:50%;
    transform: translateY(520%);
    // background:grey;
    z-index: -2;
  }
  .wrapper-left .box-left{
    position:absolute;
    width: fit-content;
    height:50px;
    font-size:5rem;
    font-weight:600;
    line-height:50px;
    text-align:center;
  }
  .wrapper-left .boxes-left {
    position:relative;
    left:-450px;
}

@media screen and (max-width: 600px) {
  .wrapper-left {
    transform: translate(-50%, 50%) !important;
    width: 200% !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 1000px) {
  .wrapper-left {
    width: 200% !important;
  }
}
