.homepage {
  text-align: center;
  letter-spacing: 0.3px;
  min-height: 200vh;
  height: auto;

  // .top {
  //   background-image: url(../../images/background_50.PNG);
  //   background-size: 40%;
  //   background-repeat: no-repeat;
  //   background-position: center 100px;

  //   .title {
  //     font-size: calc(4.375rem + 3.5vw);
  //     margin-bottom: 11.5vh;
  //   }

  //   .job-1 {
  //     font-size: calc(0.875rem + 0.5vw);
  //     opacity: 80%;
  //     margin-bottom: 4vh;
  //   }

  //   .job-2 {
  //     font-size: calc(1.275rem + 0.5vw);
  //     margin-bottom: 4vh;
  //   }

  //   .job-3 {
  //     opacity: 40%;
  //     margin-bottom: 7vh;
  //   }
  // }

  // .projects {
  //   margin-top: 30vh;

  //   h4 {
  //     text-align: left;
  //     margin-inline-start: 8vw;
  //     margin-bottom: 5vh;
  //   }
  // }
  .grow,
  .grow-small {
    transform: scale(4);
    background: white;
    mix-blend-mode: difference;
    border: none;
  }

  .grow-small {
    transform: scale(2);
  }
}
