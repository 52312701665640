.projects {
    position: relative;
    min-height: 100vh;
    width: 100vw;
    height: auto;
    overflow: hidden;
    h3 {
        font-size: 5rem;
        position: absolute;
        top: 0;
        margin-bottom: 2rem;
    }
}