.footer-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -2;

    background-image: url(../../images/Noisenoise.png);
    mix-blend-mode: soft-light;
  }

  .text-path {
    position: absolute;
    top: 12%;
    left: 0;

    svg {
      width: 95vw;

      .text-strip-wrapper {
        position: relative;
        width: 250%;
        height: 80px;
        right: 50%;

        .texts {
          position: relative;
          left: -1050px;

          .strip-t {
            fill: #eadede;
            font-family: "library3am";
            font-size: 2rem;
            width: fit-content;
            position: absolute;
            text-align: center;
          }
        }
      }
    }
  }

  .text-sec {
    position: absolute;
    bottom: 0;
    width: 90vw;
    height: 80vh;
    background-color: #201042;
    color: #eadede;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      display: block;
      left: auto;
      right: auto;
      font-size: 3rem;
      margin-bottom: 3rem;
      width: 70vw;
    }

    a {
      text-transform: lowercase;
      font-size: 1.5rem;
      font-family: "Urbanist", sans-serif;
      font-weight: 200;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease;
      position: absolute;

      &#contact {
        width: 23.5%;
        height: 18.89%;
        background-color: rgba($color: #2e0a52, $alpha: 0.83);
        top: 0;
        right: 20%;
      }

      &#instagram {
        width: 18.73%;
        height: 28.628%;
        background-color: #210955;
        top: 0;
        left: 10%;
      }

      &#email {
        width: 40.08%;
        height: 23.63%;
        background-color: #1d0949;
        top: 50%;
        transform: translateY(-50%);
        right: 0;

        &:hover {
          transform: translateY(calc(-50% - 5px));
        }
      }

      &#linkedin {
        width: 16.97%;
        height: 28.63%;
        background-color: #280672;
        bottom: 28%;
        left: 27%;
      }

      &:hover {
        transform: translateY(-5px);

        svg {
          stroke-dashoffset: 0;
          opacity: 1;
          transition: opacity 0s,
            stroke-dashoffset 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
      }

      svg {
        fill: none;
        stroke: rgba($color: #fff, $alpha: 0.6);
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke-dasharray: 338;
        stroke-dashoffset: 338;
        stroke-linecap: round;
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(180px);
        opacity: 0;
        transform: translate(-50%, -50%);
        transition: stroke-dashoffset 0s 0.2s, opacity 0.2s;
        z-index: -1;
      }
    }

    .footer-bottom {
      span {
        position: absolute;
        bottom: 0;
        font-family: "Urbanist", sans-serif;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &#left {
          left: 0%;
          width: 17.82%;
          height: 32.58%;
          background-color: #302255;
        }

        &#right {
          left: 47%;
          width: 35.21%;
          height: 12.24%;
          background-color: rgba($color: #2b1078, $alpha: 0.57);
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .text-path {
    top: 5% !important;
    svg {
      width: 105vw !important;
    }
  }

  .text-sec {
    width: 100vw !important;
    height: 90vh !important;
  }
  a {
    font-size: 1rem !important;

    &#contact {
      width: 25.5% !important;
      height: 16.89% !important;
    }

    &#instagram {
      width: 38.73% !important;
      height: 18.628% !important;
      top: 10% !important;
    }

    &#email {
      width: 70.08% !important;
      height: 13.63% !important;
      top: 40% !important;
    }

    &#linkedin {
      width: 30.97% !important;
      height: 18.63% !important;
      top: 53% !important;
      left: 35% !important;
    }
  }

  .footer-bottom {
    span {
      font-size: 0.8rem !important;
      bottom: 0% !important;

      &#left {
        left: -10% !important;
        width: 37.82% !important;
        height: 22.58% !important;
      }

      &#right {
        left: 70% !important;
        width: 45.21% !important;
        height: 12.24% !important;
      }
    }
  }
}

@media screen and (min-width: 601px) and (max-width: 1000px) {
  .text-path {
    top: 18% !important;
    svg {
      width: 105vw !important;
    }
  }

  .text-sec {
    width: 100vw !important;
  }
  a {
    &#contact {
      width: 23.5% !important;
      height: 15.89% !important;
    }

    &#instagram {
      width: 28.73% !important;
      height: 18.628% !important;
      top: 15% !important;
    }

    &#email {
      width: 50.08% !important;
      height: 13.63% !important;
      top: 40% !important;
    }

    &#linkedin {
      width: 23.97% !important;
      height: 14.63% !important;
      top: 53% !important;
      left: 35% !important;
    }
  }

  span {
    &#left {
      left: 0% !important;
      width: 27.82% !important;
      height: 20.58% !important;
    }

    &#right {
      left: 67% !important;
      width: 40.21% !important;
      height: 10.24% !important;
    }
  }
}
